import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';
import {
  LAYOUT_TYPE_MAIN_PAGE,
  IS_SHOW_SUBCATEGORIES_ENABLED,
  IS_HEADER_ENABLED,
  MAIN_PAGE_HEADER_HEIGHT,
  MAIN_PAGE_TEXT_ALIGNMENT,
  CARD_SIZE,
  IS_MAIN_PAGE_HEADER_ENABLED,
  HEADER_HEIGHT,
  CATEGORY_TEXT_ALIGNMENT,
  LAYOUT_TYPE,
  FORUM_STRUCTURE,
  POST_SORTING,
  IS_VIEWS_COUNT_ENABLED,
  IS_LIST_HASHTAGS_ENABLED,
  IS_SOCIAL_SHARING_LINKS_ENABLED,
  IS_PINTEREST_PIN_ENABLED,
  IS_BADGES_ENABLED,
  IS_LOGIN_WIDGET_ENABLED,
  IS_RCE_IMAGE_ENABLED,
  IS_RCE_VIDEO_ENABLED,
  IS_RCE_DIVIDER_ENABLED,
  IS_RCE_HTML_ENABLED,
  IS_RCE_GIPHY_ENABLED,
  IS_RCE_FILE_UPLOAD_ENABLED,
  IS_RCE_EMOJI_ENABLED,
  IS_RCE_EVENTS_EMBED_ENABLED,
  IS_RCE_BOOKINGS_EMBED_ENABLED,
  IS_RCE_STORES_EMBED_ENABLED,
  IS_RCE_COLLAPSIBLE_LIST_ENABLED,
  IS_RCE_POLLS_ENABLED,
  IS_RCE_CODE_BLOCK_ENABLED,
  IS_FOOTER_POSTS_ENABLED,
  IS_SIMILAR_POSTS_ENABLED,
  IS_AUTO_SUGGESTIONS_ENABLED,
} from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import { LayoutTypes } from './Settings/constants/layout-types';
import { TextAlignment } from './Settings/constants/text-alignment';
import { CardSizes } from './Settings/constants/card-sizes';
import { Structure } from './Settings/constants/structure';
import { PostSortingSettings } from './Settings/constants/post-sorting';

export type IStylesParams = {
  mainPageLayout: StyleParamType.Number;
  showSubcategories: StyleParamType.Boolean;
  mainPageShowHeader: StyleParamType.Boolean;
  mainPageHeaderHeight: StyleParamType.Number;
  mainPageHeaderTextAlignment: StyleParamType.Number;
  postsPageLayout: StyleParamType.Number;
  postsPageCardSize: StyleParamType.Number;
  postsPageShowHeader: StyleParamType.Boolean;
  postsPageHeaderHeight: StyleParamType.Number;
  postsPageHeaderTextAlignment: StyleParamType.Number;
  mainPageStructure: StyleParamType.Number;
  postSorting: StyleParamType.Number;
  isViewCounterEnabled: StyleParamType.Boolean;
  isHashtagsEnabled: StyleParamType.Boolean;
  isSocialSharingIconsEnabled: StyleParamType.Boolean;
  isPinterestPinEnabled: StyleParamType.Boolean;
  isBadgesEnabled: StyleParamType.Boolean;
  isLoginWidgetEnabled: StyleParamType.Boolean | StyleParamType.Number;
  isRceImageEnabled: StyleParamType.Boolean;
  isRceVideoEnabled: StyleParamType.Boolean;
  isRceDividerEnabled: StyleParamType.Boolean;
  isRceHtmlEnabled: StyleParamType.Boolean;
  isRceCodeBlockEnabled: StyleParamType.Boolean;
  isRceGiphyEnabled: StyleParamType.Boolean;
  isRceFileUploadEnabled: StyleParamType.Boolean;
  isRceEmojiEnabled: StyleParamType.Boolean;
  isRceEventsEmbedEnabled: StyleParamType.Boolean;
  isRceBookingsEmbedEnabled: StyleParamType.Boolean;
  isRceStoresEmbedEnabled: StyleParamType.Boolean;
  isRceCollapsibleListEnabled: StyleParamType.Boolean;
  isRcePollsEnabled: StyleParamType.Boolean;
  isFooterPostsEnabled: StyleParamType.Boolean;
  isSimilarPostsEnabled: StyleParamType.Boolean;
  isAutoSuggestionsEnabled: StyleParamType.Boolean;
};

export default createStylesParams<IStylesParams>({
  mainPageLayout: {
    key: LAYOUT_TYPE_MAIN_PAGE,
    type: StyleParamType.Number,
    getDefaultValue: () => LayoutTypes.PINBOARD,
  },
  showSubcategories: {
    key: IS_SHOW_SUBCATEGORIES_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  mainPageShowHeader: {
    key: IS_MAIN_PAGE_HEADER_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  mainPageHeaderHeight: {
    key: MAIN_PAGE_HEADER_HEIGHT,
    type: StyleParamType.Number,
    getDefaultValue: () => 250,
  },
  mainPageHeaderTextAlignment: {
    key: MAIN_PAGE_TEXT_ALIGNMENT,
    type: StyleParamType.Number,
    getDefaultValue: () => TextAlignment.CENTER,
  },
  postsPageLayout: {
    key: LAYOUT_TYPE,
    type: StyleParamType.Number,
    getDefaultValue: () => LayoutTypes.PINBOARD,
  },
  postsPageCardSize: {
    key: CARD_SIZE,
    type: StyleParamType.Number,
    getDefaultValue: () => CardSizes.MEDIUM,
  },
  postsPageShowHeader: {
    key: IS_HEADER_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  postsPageHeaderHeight: {
    key: HEADER_HEIGHT,
    type: StyleParamType.Number,
    getDefaultValue: () => 250,
  },
  postsPageHeaderTextAlignment: {
    key: CATEGORY_TEXT_ALIGNMENT,
    type: StyleParamType.Number,
    getDefaultValue: () => TextAlignment.CENTER,
  },
  mainPageStructure: {
    key: FORUM_STRUCTURE,
    type: StyleParamType.Number,
    getDefaultValue: () => Structure.REGULAR,
  },
  postSorting: {
    key: POST_SORTING,
    type: StyleParamType.Number,
    getDefaultValue: () => Number(PostSortingSettings.SORT_BY_LAST_ACTIVITY),
  },
  isViewCounterEnabled: {
    key: IS_VIEWS_COUNT_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isHashtagsEnabled: {
    key: IS_LIST_HASHTAGS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isSocialSharingIconsEnabled: {
    key: IS_SOCIAL_SHARING_LINKS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isPinterestPinEnabled: {
    key: IS_PINTEREST_PIN_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  isBadgesEnabled: {
    key: IS_BADGES_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isLoginWidgetEnabled: {
    key: IS_LOGIN_WIDGET_ENABLED,
    type: StyleParamType.Boolean || StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  isRceImageEnabled: {
    key: IS_RCE_IMAGE_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceVideoEnabled: {
    key: IS_RCE_VIDEO_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceDividerEnabled: {
    key: IS_RCE_DIVIDER_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceHtmlEnabled: {
    key: IS_RCE_HTML_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  isRceCodeBlockEnabled: {
    key: IS_RCE_CODE_BLOCK_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceGiphyEnabled: {
    key: IS_RCE_GIPHY_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceFileUploadEnabled: {
    key: IS_RCE_FILE_UPLOAD_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceEmojiEnabled: {
    key: IS_RCE_EMOJI_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceEventsEmbedEnabled: {
    key: IS_RCE_EVENTS_EMBED_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceBookingsEmbedEnabled: {
    key: IS_RCE_BOOKINGS_EMBED_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceStoresEmbedEnabled: {
    key: IS_RCE_STORES_EMBED_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRceCollapsibleListEnabled: {
    key: IS_RCE_COLLAPSIBLE_LIST_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isRcePollsEnabled: {
    key: IS_RCE_POLLS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isFooterPostsEnabled: {
    key: IS_FOOTER_POSTS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isSimilarPostsEnabled: {
    key: IS_SIMILAR_POSTS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  isAutoSuggestionsEnabled: {
    key: IS_AUTO_SUGGESTIONS_ENABLED,
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
});
